import type { Auth } from '~/types/types'

export default defineNuxtRouteMiddleware(() => {
  const { $localePath } = useNuxtApp()

  const auth = useState<Auth>('auth')
  const { isMobile } = useDevice()

  if (auth?.value?.authenticated) {
    if (isMobile) {
      return navigateTo(
        $localePath({
          name: 'myaccount-menu',
        }),
      )
    } else {
      return navigateTo(
        $localePath({
          name: 'myaccount-bookings',
        }),
      )
    }
  }
})
